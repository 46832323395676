import React from "react"

import { Text } from "@blueprintjs/core"
import InfoBlock from "../global/InfoBlock/InfoBlock"
import ProLayout from "../layout/ProLayout/ProLayout"


const PrivacyPolicy = () => {
  return (
    <ProLayout>
      <InfoBlock
        headline="Privacy Policy / Your Privacy Rights"
        body={
          <>
            <Text tagName="p">
              <strong>Last Updated:</strong> May 26, 2020
            </Text>
            <Text tagName="p">
              <strong>Purpose of the Privacy Policy.</strong>
            </Text>
            <Text tagName="p">
              We are committed to respecting and protecting your privacy rights,
              and we created this Privacy Policy to give you (the user)notice of
              how your private information will (and will not) be used by us in
              connection with our website (
              <a
                href="https://www.climatecheck.com"
                target="_blank"
                rel="noopener"
              >
                www.climatecheck.com
              </a>
              ) and our mobile app together with its subdomains, content and
              services. In simplified form (greater detail is below), our policy
              can be summarized as follows:
            </Text>
            <ol>
              <li>
                We may collect some information on you, but you control how much
                you share about yourself.
              </li>
              <li>
                We do not collect information from children under 13 years of
                age.
              </li>
              <li>We take your information seriously.</li>
              <li>
                We will not sell or rent your personally identifiable data but
                may share with our vendors, suppliers and partners in connection
                with the services you are seeking.
              </li>
              <li>
                If we use a third party to assist us, they will be bound to
                protect your information.
              </li>
              <li>We may use and sell aggregate information.</li>
              <li>
                In certain legal situations, we may be compelled to disclose
                your personal information, but we will let you know if that
                occurs.
              </li>
              <li>
                If you are outside the United States, you understand and agree
                that we may store your information in the United States.
              </li>
            </ol>
            <Text tagName="p">
              <strong>
                This Privacy Policy applies to the websites and app where it
                appears.
              </strong>
            </Text>
            <Text tagName="p">
              This policy describes how we treat personal information on the
              websites and mobile apps where it appears (the “Platforms”). By
              interacting with us, you consent to the data we collect and how we
              use and share it in accordance with this Privacy Policy.
            </Text>
            <Text tagName="p">
              This privacy policy applies to how we use your information.
            </Text>
            <Text tagName="h2">What Data We Collect.</Text>
            <Text tagName="p">
              <strong>Customers and Users voluntarily provide us data,</strong>{" "}
              including data that can be used to directly or indirectly identify
              an individual (“Personally Identifiable Data”) when they access
              our Platform or use and purchase our products and services.
            </Text>
            <Text tagName="p">
              Data Provided By Users and Customers May Include:
            </Text>
            <ul>
              <li>
                Name, address, email, telephone number, username and password
                (collectively referred to as “Account Information”)
              </li>
              <li>
                Credit card, debit card, banking or other payment information
                (which may be provided to a third party provider that we use)
              </li>
              <li>
                Information submitted as a result of completing forms, entering
                a promotion or survey or subscribing to, commenting on or
                downloading information from our Platform
              </li>
              <li>
                Customer or User content stored, processed, transmitted or
                maintained using our products or services
              </li>
              <li>
                File system information such as the configuration of any device
                registered for use with our Platform
              </li>
              <li>
                Technical information including IP addresses, browser type,
                device type, internet service provider, etc.
              </li>
            </ul>
            <Text tagName="p">
              <strong>
                We may collect contact information and personal information on
                the Platforms.{" "}
              </strong>{" "}
              For example, we may collect your phone number when you use the
              Platforms. We also collect your name, email address and street
              address if you register with us.
            </Text>
            <Text tagName="p">
              <strong>
                {" "}
                We may collect information about your device and Internet usage.{" "}
              </strong>{" "}
              If you use the Platforms, we might collect information from your
              computer or phone. We may also collect information about the parts
              of our Platforms you use or third party apps or websites you visit
              when you leave our Platforms. We may work with third parties who
              collect information about you when you use our app.
            </Text>
            <Text tagName="p">
              <strong>We may collect other information.</strong> For example, we
              may collect your location using GPS, a cellular network location,
              Wi-Fi networks, browser services, or information you provide. We
              might look at how often you use the Platforms.
            </Text>
            <Text tagName="p">
              <strong>Aggregate Information.</strong> To the extent permitted by
              applicable law, we may use, process, transfer and store customer
              and user data in an anonymous (or pseudonymous) and aggregated
              manner. We may combine such data with other information collected,
              including information from third-party sources. By using the
              products and services, our customers and users agree that we are
              permitted to collect, use, share and store anonymized (or
              pseudonymized) aggregated data collected through the products and
              services for benchmarking, analytics, metrics, research,
              reporting, machine learning and other legitimate business
              purposes.
            </Text>
            <Text tagName="h3">How We Collect Data.</Text>
            <Text tagName="p">
              <strong>We collect information directly from you.</strong> For
              example, we collect information if you register on the Platforms
              or use our apps and services.
            </Text>
            <Text tagName="p">
              <strong>We collect information from you passively.</strong> For
              example, we may collect information from your phone, like your
              UDID. Our vendors might also use scripts or other tools to track
              your activities on our Platform. They may do this to make sure our
              Platforms function properly.
            </Text>
            <Text tagName="p">
              <strong>
                We may get information about you from third parties.{" "}
              </strong>{" "}
              For example, our affiliates or partners may give us information
              about you. If our Platforms have social media or other app
              integrations, such third parties may also give us information
              about you.
            </Text>
            <Text tagName="p">
              <strong>We combine information.</strong> For example, we may
              combine information that we have collected offline with
              information we collect online. Or we may combine information we
              collect online with information we’ve collected separately. We may
              also combine information we collect from you with information we
              receive from third parties.
            </Text>
            <Text tagName="h4">Why We Use Data.</Text>
            <Text tagName="p">
              <strong>We use information to administer the Platforms.</strong>
            </Text>
            <Text tagName="p">
              <strong>
                We use information to respond to your requests or questions.{" "}
              </strong>{" "}
              For example, we might use your information to respond to your
              customer feedback.
            </Text>
            <Text tagName="p">
              <strong>
                We use information to communicate with you about your account or
                our relationship.{" "}
              </strong>{" "}
              We may contact you about our account or feedback. We might also
              contact you about this Policy or our Terms.
            </Text>
            <Text tagName="p">
              <strong>
                We use information to improve our products and services.{" "}
              </strong>{" "}
              We may use your information to make our website, apps and products
              better. We might use your information to customize your experience
              with us.
            </Text>
            <Text tagName="p">
              <strong>We use information for security purposes.</strong> We may
              use information to protect our company, our customers, and our
              websites and apps.
            </Text>
            <Text tagName="p">
              <strong>We use information for marketing purposes.</strong> For
              example, we might provide you with information about new products
              and special offers from us. We might also use your information to
              serve you ads about products and offers. We might tell you about
              new features or updates. These might be third party offers or
              products we think you might find interesting. To manage this, read
              the choices section below. We may also use push notifications on
              our mobile apps.
            </Text>
            <Text tagName="p">
              <strong>
                Where the processing is necessary for the purposes of our
                legitimate interest, while taking into account any individual
                interests.{" "}
              </strong>{" "}
              Our legitimate interests include providing the products and
              services, internal record-keeping and administrative purposes and
              to operate, maintain and improve the Platform.
            </Text>
            <Text tagName="p">
              <strong>We use information as otherwise permitted by law.</strong>
            </Text>
            <Text tagName="h5">How We Use Cookies And Other Technologies.</Text>
            <Text tagName="p">
              We use certain automatic data collection technologies such as
              cookies, web beacons, pixel tags and other technologies to collect
              data, including Personally Identifiable Data, when users or
              customers visit the Platform, use the products and services or
              interact with us, and may share this data with our third-party
              marketing vendors (including for example, advertising networks and
              providers of external services like web traffic analysis services
              and analytics tools). We explain these technologies below.
            </Text>
            <Text tagName="p">
              Cookies. Cookies are small text files placed on a computer by a
              web server when browsing online and are used to store user
              preference data so that a web server doesn't have to repeatedly
              request this information. A user may block cookies by activating
              the settings on the browser that blocks all or some cookies.
              However, if a user blocks all cookies (including strictly
              necessary cookies), a user may not be able to access all or parts
              of our Platform. We use the following cookies:
            </Text>
            <ul>
              <li>
                Strictly Necessary Cookies. These cookies are required for the
                operation of our Platform. They include, for example, cookies
                that enable a customer to log-in to secure areas of our Platform
                and use e-commerce.
              </li>
              <li>
                Analytical and Performance Cookies. These cookies allow us to
                recognize and count the number of users visiting our Platform
                and see how those users navigate our Platform. This helps us to
                improve our Platform.
              </li>
              <li>
                Functionality Cookies. These cookies recognize a user that
                returns to our Platform. This enables us to personalize our
                content, greet the user by name and remember preferences, for
                example, choice of language or region.
              </li>
              <li>
                Targeting Cookies. These cookies record visits to our Platform
                and the links followed. We use this information to improve our
                Platform and ensure the advertising displayed is relevant to
                users.
              </li>
            </ul>
            <Text tagName="p">
              Web Beacons. A web beacon is a small pixel incorporated into a web
              page or email to keep track of activity on the page or email. A
              web beacon helps us better manage the content of our Platform by
              informing us of what content is effective.
            </Text>
            <Text tagName="h5">Who We Share Data With.</Text>
            <Text tagName="p">
              <strong>
                We will share information with individuals and companies that
                you have designated. We do not otherwise sell, trade, or
                transfer to outside parties your Personally Identifiable Data,
                except as set forth below.{" "}
              </strong>
            </Text>
            <Text tagName="p">
              <strong>
                We will share Personally Identifiable Data with our vendors and
                partners.{" "}
              </strong>{" "}
              These are third parties who perform services on our behalf or who
              help us to improve our website and products. For example, we may
              share information with vendors who send emails for us.
            </Text>
            <Text tagName="p">
              <strong>
                We will share information if we think we have to in order to
                comply with the law or to protect ourselves.{" "}
              </strong>{" "}
              For example, we will share information to respond to a court order
              or subpoena. We may share it if a government agency or
              investigatory body requests. We might share information when we
              are investigating potential fraud.
            </Text>
            <Text tagName="p">
              <strong>
                {" "}
                We may share information with any successor to all or part of
                our business.{" "}
              </strong>{" "}
              For example, if part of our business was sold, we may share
              information as part of that transaction.
            </Text>
            <Text tagName="p">
              <strong>
                {" "}
                We may share your contact information with other users.{" "}
              </strong>{" "}
              You have the ability to opt out of this sharing if you like.
            </Text>
            <Text tagName="p">
              <strong>
                {" "}
                We may share information for other reasons we may describe to
                you.{" "}
              </strong>
            </Text>
            <Text tagName="h5">Opt Out.</Text>
            <Text tagName="p">
              <strong>
                {" "}
                You can opt out of receiving our marketing emails.{" "}
              </strong>{" "}
              To stop receiving our promotional emails, follow the instructions
              in any promotional message you get from us. Even if you opt out of
              getting marketing messages, we will still send you transactional
              messages. These include responses to your questions.
            </Text>
            <Text tagName="p">
              <strong>You can control cookies and tracking tools.</strong> Your
              browser may give you the ability to control cookies or other
              tracking tools. How you do so depends on the type of tool. Certain
              browsers can be set to reject browser cookies. To control flash
              cookies, which we may use on certain websites from time to time,
              you can go{" "}
              <a
                href="https://optout.aboutads.info/?c=2&lang=EN"
                target="_blank"
              >
                {" "}
                here{" "}
              </a>{" "}
              . Why? Because flash cookies cannot be controlled through your
              browser settings.
            </Text>
            <Text tagName="p">
              <strong>
                {" "}
                Our Do Not Track Policy: Some browsers have “do not track”
                features that allow you to tell a website not to track you.
                These features are not all uniform. We do not currently respond
                to those signals.
              </strong>{" "}
              If you block cookies, certain features on our sites may not work.
              If you block or reject cookies, not all of the tracking described
              here will stop.
            </Text>
            <Text tagName="p">
              <strong>You can control tools on your mobile devices.</strong> For
              example, you can turn off the GPS locator or push notifications on
              your phone.
            </Text>
            <Text tagName="h5">Retention Of Data.</Text>
            <Text tagName="p">
              We may retain data, including Personally Identifiable Data, for as
              long as necessary to deliver the products and services or as
              needed for other lawful purposes. We may retain anonymized or
              pseudonymized, aggregated data indefinitely or to the extent
              permitted under applicable law.
            </Text>
            <Text tagName="h5">Your California Privacy Rights.</Text>
            <Text tagName="p">
              If you reside in California, you have the right to ask us one time
              each year if we have shared personal information with third
              parties for their direct marketing purposes. To make a request,
              please send us an email at{" "}
              <a href="mailto:info@climatecheck.com">info@climatecheck.com</a>,
              or write to us at the address listed below. Indicate in your
              letter that you are a California resident making a “Shine the
              Light” inquiry.
            </Text>
            <Text tagName="h5">Your Rights.</Text>
            <Text tagName="p">
              Subject to applicable data protection laws, customers and users
              have the following rights with respect to our handling of
              Personally Identifiable Data:
            </Text>
            <ul>
              <li>
                Access. The right to access Personally Identifiable Data held by
                us.
              </li>
              <li>
                Rectification. The right to request correction of Personally
                Identifiable Data that is incomplete, incorrect, unnecessary or
                outdated.
              </li>
              <li>
                Right to be Forgotten. The right to request erasure of all
                Personally Identifiable Data that is incomplete, incorrect,
                unnecessary or outdated within a reasonable period of time. We
                will do everything possible to erase Personally Identifiable
                Data if a user or customer so requests. However, we will not be
                able to erase all Personally Identifiable Data if it is
                technically impossible due to limitations of existing technology
                or for legal reasons, such as we are mandated by applicable law
                to retain Personally Identifiable Data.
              </li>
              <li>
                Restriction of Processing. The right to request restriction of
                processing Personally Identifiable Data for certain reasons,
                provided we do not have an overriding, legitimate interest to
                continue processing.
              </li>
              <li>
                Data Portability. If requested, we will provide Personally
                Identifiable Data in a structured, secure, commonly used and
                machine-readable format.
              </li>
              <li>
                Right to Withdraw Consent. If Personally Identifiable Data is
                processed solely based on consent, and not based on any other
                legal basis, customers can withdraw consent at any time.
              </li>
            </ul>
            <Text tagName="p">
              To exercise any of the above listed rights, email us at{" "}
              <a href="mailto:info@climatecheck.com">info@climatecheck.com</a>,
              or mail ClimateCheck, Inc., Attn: Privacy, 100 Northern Ave.
              Boston, MA 02210. We will process requests in accordance with
              applicable law and within a reasonable period of time.
            </Text>
            <Text tagName="h5">Regarding Children.</Text>
            <Text tagName="p">
              Our Platforms are meant for adults. We do not knowingly collect
              Personally Identifiable Data from children under 13 without
              permission from a parent or guardian. If you are a parent or legal
              guardian and think your child under 13 has given us information,
              you can email us at{" "}
              <a href="mailto:info@climatecheck.com">info@climatecheck.com</a>.
              You can also write to us at the address listed at the end of this
              policy. Please mark your inquiries “COPPA Information Request.”
              Parents, you can learn more about how to protect children's
              privacy on-line here.
            </Text>
            <Text tagName="h5">Information Storage in the United States.</Text>
            <Text tagName="p">
              Information we maintain may be stored both within and outside of
              the United States. If you live outside of the United States, you
              understand and agree that we may transfer your information to the
              United States. This site is subject to U.S. laws, which may not
              afford the same level of protection as those in your country.
            </Text>
            <Text tagName="h5">Security.</Text>
            <Text tagName="p">
              The Internet and mobile apps are not 100% secure. We cannot
              promise that your use of our Platforms will be completely safe. We
              encourage you to use caution when using apps and the Internet.
              This includes not sharing your passwords. To learn more about how
              to protect yourself online, visit{" "}
              <a href="https://www.ftc.gov" target="_blank" rel="noopener">
                www.ftc.gov{" "}
              </a>
              .
            </Text>
            <Text tagName="p">
              We keep personal information as long as it is necessary or
              relevant for the practices described in this Policy. We also keep
              information as otherwise required by law.
            </Text>
            <Text tagName="h5">
              Linking to other sites or have third party services on our
              Platforms we don’t control.
            </Text>
            <Text tagName="p">
              Our Platforms may have links to websites we do not control. Our
              Platforms may also have plugins or host other third party services
              that we don’t control. This policy does not apply to the privacy
              practices of those websites or third parties. When you interact
              with those websites or third parties, they may collect information
              from you. Read the privacy policy of other websites carefully. We
              are not responsible for these third party practices.
            </Text>
            <Text tagName="h5">Contact us if you have more questions.</Text>
            <Text tagName="p">
              If you have any questions about this Policy or want to correct or
              update your information, please email us at{" "}
              <a href="mailto:info@climatecheck.com">info@climatecheck.com</a>
            </Text>
            <Text tagName="p">You can also write to us at:</Text>
            <Text tagName="p">
              ClimateCheck, Inc.,
              <br />
              1400 Village Square Blvd #3-81914,
              <br />
              Tallahassee, FL 32313-7500
            </Text>
            <Text tagName="h5">We may update this Policy.</Text>
            <Text tagName="p">
              This Privacy Policy may be updated from time to time, but the
              changes will not apply retroactively. We will notify you of any
              material changes to our Policy as required by law. We will also
              post an updated copy on our website and apps. Please check our
              website and apps periodically for updates.
            </Text>
            <Text tagName="p">
              Copyright &copy; {new Date().getFullYear()} ClimateCheck&trade;,
              Inc. All rights reserved.
            </Text>
          </>
        }
      />
    
    </ProLayout>
  )
}

export default PrivacyPolicy
