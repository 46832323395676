import React from "react"
import SEO from "@components/SEO"
import PrivacyPolicy from "@components/PrivacyPolicyPage"
import "@styles/Components/privacyPolicy.scss"
import { graphql } from "gatsby"
import CorporativeFooter from "@components/global/CorporativeFooter"
import Footer from "@components/global/Footer"

const PrivacyPolicyPage = ({ data: { seo } }) => {
  const customSeo = {
    title: seo?.seo?.title || "Privacy Policy",
    link: "https://climatecheck.com/privacy-policy",
    description:
      seo?.seo?.description ||
      "We are committed to respecting and protecting your privacy rights",
    ...seo?.seo,
  }

  return (
    <>
      <SEO seo={customSeo} />
      <PrivacyPolicy />
      <div className="corporative-footer-contact">
        <p>
          Questions or feedback? Contact us at{" "}
          <a href="mailto:info@climatecheck.com" rel="noopener">
            info@climatecheck.com
          </a>
        </p>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query PrivacyPolicy {
    seo: sanityPagesSeo(slug: { current: { eq: "/privacy-policy" } }) {
      seo {
        title
        keywords
        description
      }
    }
  }
`
